import { Link } from "react-router-dom";

export default function Home(){

    return <div className="container max-w-lg px-4 py-32 mx-auto mt-px text-left md:max-w-none md:text-center">
            <h1 className="text-5xl font-extrabold leading-10 tracking-tight text-left text-blue-600 md:text-center sm:leading-none md:text-6xl lg:text-7xl"><span className="inline md:block">Start Helping</span> <span className="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-yellow-500 to-yellow-400 md:inline-block">Ukraine Now!</span></h1>
            <div className="mx-auto mt-5 text-gray-500 md:mt-12 md:max-w-lg md:text-center lg:text-lg">We purchase drones & parts to send to Ukraine from the used market (mostly)</div>
            <div className="flex flex-col items-center mt-12 text-center">
                <span className="relative inline-flex w-full md:w-auto">
                    <Link to="/donate" type="button" className="cursor-pointer inline-flex items-center justify-center w-full px-8 py-4 text-base font-bold leading-6 text-white bg-blue-600 border border-transparent rounded-full md:w-auto hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                        Donate Now
                    </Link>
                    <span className="absolute top-0 right-0 px-2 py-1 -mt-3 -mr-6 text-xs font-medium leading-tight text-white bg-green-400 rounded-full">$5+</span>
                </span>
                <Link to="about" className="mt-3 text-sm text-blue-500 cursor-pointer">Learn More</Link>
            </div>
        </div>
}