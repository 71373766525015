export default function Donate(){

    return <section className="relative py-16 bg-white min-w-screen animation-fade animation-delay tails-selected-element">
    <div className="container px-0 px-8 mx-auto sm:px-12 xl:px-5">
        <h3 className="mt-1 mb-12 text-2xl font-bold text-left text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-yellow-500 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0">
            Donate Now
        </h3>
        <stripe-pricing-table pricing-table-id="prctbl_1NKAp3Eyw9kb5MC04sC1zXjp"
        publishable-key="pk_live_51MCaYGEyw9kb5MC0FhNaVugZNlquy2NQ5Ys93rLU8M45v0hkCVXsSechEuYF2140dY7CuI7CBML843YNLvIehPTN00AB7YeIUm">
        </stripe-pricing-table>
    </div>
</section>
}