import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const contactSubmitted = atom({
    key: 'contactSubmitted', 
    default: false,
    effects_UNSTABLE: [persistAtom],
  });
const confirmationModalOpen = atom({
    key: 'confirmationModalOpen', 
    default: false,
    effects_UNSTABLE: [persistAtom],
  });
  const email = atom({
    key: 'email', 
    default: '',
    effects_UNSTABLE: [persistAtom],
  });
  const name = atom({
    key: 'name', 
    default: '',
    effects_UNSTABLE: [persistAtom],
  });
  export { contactSubmitted, confirmationModalOpen, name, email };