import { Link } from "react-router-dom";

export default function TrackerCard(props){
    const { title, price, pledged, imgUrl, link } = props;
    
    const calculateWidth = () => {
        const roundown = Math.floor(pledged*100/price);
        const widthCalc = Math.ceil(roundown*12 / 100)
        return `w-${widthCalc}/12`
    }

    return <div className="flex flex-col items-center mt-12 text-center">
            <a href={link}  target="_blank" className="cursor-pointer flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 ">
                <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={imgUrl} alt={title} />
                <div className="flex flex-col justify-between p-4 leading-normal">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{title}</h5>
                    <p className="mb-3 font-normal text-gray-700">${price}</p>
                </div>
                
            </a>
                <div className="flex w-full justify-center p-4 leading-normal">
                    <div className="flex w-2/5 flex-row items-center mt-1 rounded-md">
                        <div className=" w-1/5 text-yellow-300 tracking-tighter bg-blue-600 rounded-md">
                            <span className="text-xl mr-2">${pledged} of ${price}</span>
                        </div>
                        <div className="w-3/5">
                            <div className="bg-yellow-300 w-full rounded-lg h-2">
                                <div className={`${calculateWidth()} bg-blue-600 rounded-lg h-2`}></div>
                            </div>
                        </div>
                        <div className="w-1/5 text-blue-500 pl-3  bg-yellow-300 rounded-md">
                            <span className="text-sm font-bold">{pledged/price * 100}%</span>
                        </div>
                    </div>
                </div>
            </div>
}