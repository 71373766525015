import { useState } from "react";
import { useRecoilState } from "recoil";
import Confirmation from "../../components/confirmation/confirmation";
import { confirmationModalOpen, email, name } from "../../context/context";

export default function Contact(){
    const [confirm, setConfirm] = useRecoilState(confirmationModalOpen);
    const [contactName, setName] = useRecoilState(name);
    const [contactEmail, setEmail] = useRecoilState(email);

    return <section className="w-full bg-white tails-selected-element">

    <div className="mx-auto max-w-7xl">
        <div className="flex flex-col lg:flex-row">
            <div className="relative w-full bg-cover lg:w-6/12 xl:w-7/12 bg-gradient-to-r from-white via-white to-gray-100">
                <div className="relative flex flex-col items-center justify-center w-full h-full px-10 my-20 lg:px-16 lg:my-0">
                    <div className="flex flex-col items-start space-y-8 tracking-tight lg:max-w-3xl">
                        <div className="relative">
                            
                            <h2 className="text-5xl font-bold text-gray-900 xl:text-6xl">Have Questions?</h2>
                        </div>
                        <p className="text-2xl text-gray-700">Do you want to know more? Have any concerns?</p>
                    </div>
                    <br/>
                    <p className="mb-2  text-gray-600">Its ok, fill out the form and we will get back to you as soon as we can</p>
                </div>
            </div>

            <div className="w-full bg-white lg:w-6/12 xl:w-5/12">
                <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
                    <h4 className="w-full text-3xl font-bold"></h4>
                    <p className="text-lg text-gray-500">Your Contact details</p>
                    <div className="relative w-full mt-10 space-y-8">
                        <div className="relative">
                            <label className="font-medium text-gray-900">Name</label>
                            <input value={contactName} onChange={(e)=>setName(e.currentTarget.value)} type="text" className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" data-primary="blue-600" data-rounded="rounded-lg" placeholder="Enter Your Name"/>
                        <div data-lastpass-icon-root="true" ></div></div>
                        <div className="relative">
                            <label className="font-medium text-gray-900">Email</label>
                            <input value={contactEmail} onChange={(e)=>setEmail(e.currentTarget.value)} type="text" className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" data-primary="blue-600" data-rounded="rounded-lg" placeholder="Enter Your Email Address"/>
                        </div>
                        <div className="relative">
                           
                        </div>
                        <div className="relative">
                            <button onClick={() => setConfirm(true)} className="inline-block w-full px-5 py-4 text-lg font-medium text-center text-white transition duration-200 bg-blue-600 rounded-lg hover:bg-blue-700 ease" data-primary="blue-600" data-rounded="rounded-lg">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <Confirmation open={confirm} />
</section>
}