const faqs = [
    {
        question: "How is my dontation spent?", 
        text: "Once your donation is received, we wait to have sufficient funds in order to purchase drones on the used market either for parts or usage.", 
    },
    {
        question: "How do you deliver the hardware?", 
        text: "The goods are packed in a travel luggage. We then fly alongisde the goods to Europe and make contact with a trusted source who then takes charge of the delivery."
    },
    {
        question: "Is my donation anonymous?", 
        text: "Yes, we do not keep track of donors in order to protect your identity nor do we store the data anywhere. The third party in charge of providing the payments holds transaction records, but we do not extract the data or use it in any way."
    },
    {
        question: "How do I know that my money is well spent?", 
        text: "We try to keep things as transparent as possible. Total donations are regularly posted, as well as the equipment purchased. We update our site with status of delivery and goods sent."
    }
]

export default faqs;