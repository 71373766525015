import TrackerCard from "../../components/trackcard/trackcard";
import orders from "../../updateable/orders/orders";

export default function Status(){

    return <div className="container max-w-lg px-4 py-12 mx-auto mt-px text-left md:max-w-none md:text-center">
            <div class="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                <p class="font-bold ">Status Updates</p>
                <p class="text-sm ">This page can take up to 24hrs to update itself with the latest changes</p>
            </div>
            <h1 className="text-5xl font-extrabold leading-10 tracking-tight text-left text-blue-600 md:text-center sm:leading-none md:text-6xl lg:text-7xl"><span className="inline md:block">Goals</span> <span className="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-yellow-500 to-yellow-400 md:inline-block">Tracker</span></h1>
            {
                orders.map( order => {
                    return <TrackerCard key={order.name} link={order.link} title={order.title} price={order.price} pledged={order.pledged} imgUrl={order.imgUrl} />
                })
            }
        </div>
}