const orders = [
    {
        title: "Drone Dji Mini Se", 
        price: 400, 
        pledged: 100, 
        imgUrl: "https://scontent-lga3-1.xx.fbcdn.net/v/t45.5328-4/346508365_6164005413664933_7553754805070362670_n.jpg?stp=dst-jpg_s960x960&_nc_cat=108&ccb=1-7&_nc_sid=c48759&_nc_ohc=ck3-NlCAki4AX8nmUBo&_nc_ht=scontent-lga3-1.xx&oh=00_AfDM2_9oWHJE1gXcHqUW5vGYwEEFIwxgFQdMPJgIXRADdQ&oe=64945EA0",
        completed: false,
        shipped: false,
        inTransit: false,
        link: "https://www.facebook.com/marketplace/item/273019115080385/?ref=search&referral_code=null&referral_story_type=post&tracking=browse_serp%3A824918e0-9646-4622-9e26-9dbe83418bd9"
    },
    {
        title: "DJI Spark - Drone - Fly more combo - 5 batteries", 
        price: 400, 
        pledged: 25, 
        imgUrl: "https://scontent-lga3-2.xx.fbcdn.net/v/t45.5328-4/345821162_4917359658388594_1505621785842855671_n.jpg?stp=dst-jpg_s960x960&_nc_cat=111&ccb=1-7&_nc_sid=c48759&_nc_ohc=a3XB2rUTauoAX8Yd2t_&_nc_ht=scontent-lga3-2.xx&oh=00_AfDQit2IbhmumHaQZ6901P4zbk10A0EQgKInD9jY_0flqg&oe=649454EC",
        completed: false,
        shipped: false,
        inTransit: false,
        link: "https://www.facebook.com/marketplace/item/168708065873190/?ref=search&referral_code=null&referral_story_type=post&tracking=browse_serp%3A824918e0-9646-4622-9e26-9dbe83418bd9"
    }
]

export default orders;