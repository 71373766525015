import droneImg from '../../imgs/drone.jpg'
export default function About(){

    return <section className="flex items-center justify-center py-10 text-white bg-white sm:py-16 md:py-24 lg:py-32 tails-selected-element">
    <div className="relative max-w-3xl px-10 text-center text-white auto lg:px-0">
        <div className="flex flex-col w-full md:flex-row">
            <div className="flex justify-between">
                <h1 className="relative flex flex-col text-6xl font-extrabold text-left text-black">
                    Providing 
                    <span className="">Ukraine with</span>
                    <span className="">Drones & Parts</span>
                </h1>
            </div>
            <div className="relative top-0 right-0 h-64 mt-12 md:-mt-16  md:h-96">
                <img src={droneImg} className="object-cover mt-3 mr-5 h-80 lg:h-96"/>
            </div>
        </div>

        <div className="my-2 border-b border-gray-300 lg:my-8"></div>

        <h2 className="text-left text-gray-500 xl:text-xl">
            We are a group of citizens looking to source Ukraine with Drones & Spare parts. Donations are used as follows:
        </h2>
        <br/>
        <ul>
            <li className="text-left text-gray-500">- Purchase of used drones </li>
            <li className="text-left text-gray-500">- Purchase of drone parts </li>
            <li className="text-left text-gray-500">- Transportation/Retrirval Costs </li>
            <li className="text-left text-gray-500">- Shipping Costs </li>
            <li className="text-left text-gray-500 flex bg-gray-200 rounded-md">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                    </svg>
                    </span> In the eventuallity of non suitable or unavailability of used drones, new ones will be bought</li>
        </ul>
        <br/>
        <h3 className="text-left text-gray-500 xl:text-xl">
            Drones will be purchased by the most part out of online marketplaces from private sellers unless specified otherwise by the listing tracked. click on the tracked item to view the source.
        </h3>
        <div className="bg-blue-200 rounded-md text-blue-500">
            <h3 className="font-bold">What is needed mostly</h3>
            <br/>
            <div className="bg-blue-200 rounded-md text-blue-500 text-left">
                <ul >
                 <li>- Non Classic & Pro DJI Mavic 3's</li>
                 <li>- Any DJI Matrice</li>
                 <li>- Autel Evo Max 4t or Autel Evo 3 or higher.</li>
                 <li>- Any FPV drone with any propeller size</li>
                 <li>- Parts for DJI Mavic 3 and Parts for FPV drones for repair and assembly of broken units</li>
                </ul>
            </div>
        </div>
    </div>
</section>
}