import { Route, Routes } from "react-router";
import Header from "../header/header";
import main_routes from "../../routes/routes";
import Footer from "../footer/footer";


export default function Main(){
    return <section className="w-full h-screen px-6 pb-12 antialiased bg-white tails-selected-element" data-tails-scripts="//unpkg.com/alpinejs">
            <div className="mx-auto">
                <Header />

                <Routes>
                {
                main_routes.map( route => 
                    <Route path={route.path} key={route.name} element={route.component}>{route.name}</Route>
                    )
                }
                </Routes>
                <Footer />
            </div>
        </section>
}
