import About from "../pages/about/about";
import Contact from "../pages/contact/contact";
import Donate from "../pages/donate/donate";
import Faq from "../pages/faq/faq";
import Home from "../pages/home/home";
import Status from "../pages/status/status";

const main_routes = [
    {
        path: '/',
        name: 'Home',
        component: <Home />,
        secure: false,
        showIfLoggin: true
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: <Faq />,
      secure: false,
      showIfLoggin: true
    },
    {
        path: '/contact',
        name: 'Contact',
        component: <Contact />,
        secure: false,
        showIfLoggin: true
      },
      {
        path: '/about',
        name: 'About',
        component: <About />,
        secure: false,
        showIfLoggin: true
      },
      {
        path: '/donate',
        component: <Donate />,
        name: 'Donate',
        secure: false,
        showIfLoggin: true
      },
      {
        path: '/status',
        component: <Status />,
        name: 'Goals & Status',
        secure: false,
        showIfLoggin: true
      },
]

export default main_routes;