import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { confirmationModalOpen, contactSubmitted, email, name } from '../../context/context'
import { API } from 'aws-amplify';
import { useNavigate  } from "react-router-dom";
//import './confirmation.css';

export default function Confirmation() {
  let navigate = useNavigate ();
  let [isOpen, setIsOpen] = useRecoilState(confirmationModalOpen);
  const [submitted, setContactSubmitted] = useRecoilState(contactSubmitted);
  const emailReset = useResetRecoilState(email);
  const nameReset = useResetRecoilState(name);
  const emailConf = useRecoilValue(email);
  const nameConf = useRecoilValue(name);
  
  
  useEffect(()=>{
    if(isOpen && !submitted){
        emailContact();
    }
  }, [isOpen])

  useEffect(()=>{
    if(submitted){
    }
  }, [submitted])

  const emailContact = async () => {
    
    const myContact = {
      body: { name: nameConf, email: emailConf}
    };
    console.log(myContact)
    // const sendEmail = await API.post('drones4uapi', '/contact', myContact)
    // console.log(sendEmail)
    // if(sendEmail?.status === 200){
    //   setContactSubmitted(true);
    // }
  } 
  const closeModal = () => {
    setIsOpen(false)
    emailReset();
    nameReset();
    navigate('/');
  }

  const openModal= () => {
    //setIsOpen(true)
    console.log('szdfsdfdsfdsf')
  }

  return (
    <>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal} >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="confirmation-modal w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {
                      (submitted) ? "Envoie de commande..."
                    : "Confirmation de commande"
                    }
                  </Dialog.Title>
                  <div className="mt-2 flex justify-center h-32 w-full">
                    {
                      (submitted) ?
                        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
                        :
                        <p className="text-sm text-gray-500 text-left w-full p-8">
                          Thank you for reaching out, we will get back in touch with you as soon as we can!
                      </p>
                    }
                    
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Terminer
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}